@import "colors";
@import "misc";

.contact-form{
  input, select {
    width: 100%;
    padding-top: 0 !important;
    padding-bottom: 0rem !important;
    margin-bottom: 1rem !important;
    font-size: 1rem;
    line-height: 1.2rem;
    height: 30px !important;
    border: none;
    border-bottom: 1px solid #B2BFC5;
    outline: 0;
    background-color: transparent;
    box-sizing: border-box;
    font: inherit;
    margin-bottom: 2rem;
  }

  th {
    width: 70px; 
    display: inline-flex;
  }

  .centered-checkboxtext{
    margin: auto;
  }



  .rating{//1 rem = 16px
    padding-top: 0;
    padding-bottom: 0rem;
    margin-top: 0.6rem !important;
    margin-bottom: 1.2rem !important;
    width: 4.8%;
    height:10%;
  }

  .checkbox-rt-feedback{
    margin-top: 0.6rem;
    margin-bottom: 1.2rem;
  }

  .textbox-rt-feedback{
    width: 80%;
    height: 100px;
    padding-top: 0 !important;
    padding-bottom: 0rem !important;
    margin-top: 0.6rem !important;
    margin-bottom: 1.2rem !important;
    font-size: 1rem;
    line-height: 1rem;
    // border: 0px;

    border: 1px solid #B2BFC5;
    outline: 0;
    background-color: transparent;
    box-sizing: border-box;
    font: inherit;
    margin-bottom: 2rem;


  }

  textarea {
    width: 100%;
    height: 100px;
    padding-top: 0 !important;
    padding-bottom: 0rem !important;
    margin-bottom: 1rem !important;
    font-size: 1rem;
    line-height: 1rem;
    border: none;
    border-bottom: 1px solid #B2BFC5;
    outline: 0;
    background-color: transparent;
    box-sizing: border-box;
    font: inherit;
    margin-bottom: 2rem;
  }
  input[type=button], input[type=submit] {
    width: 20%;
    min-width: 200px;
    line-height: 1;
    background-color: $primaryColor;
    border: none;
    color: white;
    padding: 16px 32px;
    text-decoration: none;
    margin: 10px 2px !important;
    cursor: pointer;
    border-radius: 6px;
    height: 50px !important;
  }
  input[type=submit]{
    padding:0 !important;
  }

  .spinner{
    width: 100%;
    text-align: center;
  }

  label .required{
    color: red;
    padding-top: 30rem !important;
    // padding-bottom: 0 !important;
  }

  label{
    line-height: 1.2rem;
    display: block;
  }
}